








































import { Vue, Component } from 'vue-property-decorator'
import { ElForm } from 'element-ui/types/form'
import validation from '../../common/element-val'
import TextRowEditor from '@/components/TextRowEditor.vue'
import auth, { SecurityQuestionModel } from '../../services/authService'

@Component({
  components: {
    TextRowEditor
  }
})
export default class ForgotPwd extends Vue {
  $refs!: {
    loginForm: ElForm;
  }

  isLoading = false
  stepIndex = 0
  userAcc = 'debug@debug.com'
  questions: { items: SecurityQuestionModel[] } = {
    items: []
  }

  rules = {
    userAcc: [
      { trigger: 'blur', validator: validation.required() },
      { trigger: 'blur', validator: validation.email() }]
  }

  async loadQuestions () {
    const res = await auth.getSecurityQuestionsAsync(this.userAcc)
    if (res.Result && res.Result.length > 0) {
      this.questions.items = res.Result
      this.stepIndex = 1
    } else {
      await this.$alert(res.Error)
    }
  }

  submit () {
    this.$refs.loginForm.validate(async (valid: boolean) => {
      console.log(valid)
    })
  }
}
